import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class MaintenanceService {
  private jsonUrl =
    "https://opdsettings.s3.sa-east-1.amazonaws.com/maintenance-web.json";

  constructor(private http: HttpClient) {}

  getMaintenanceStatus(): Observable<any> {
    // return of({
    //   maintenance: true,
    //   message:
    //     "Mas não se preocupe, seus dados estão seguros, a previsão de término é às 04:00h do dia 01/02/2025",
    // });

    return this.http.get<any>(this.jsonUrl).pipe(
      catchError((error) => {
        console.error("Erro ao obter status de manutenção:", error);
        return of({
          maintenance: false,
          message: "App funcionando normalmente",
        });
      })
    );
  }
}
