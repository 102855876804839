<div class="container mt-4">
  <h1 class="h4 font-weight-bold mb-2">Materiais de divulgação</h1>
  <p class="text-muted mb-4">
    Materiais editáveis para personalizar e impulsionar suas redes sociais.
  </p>
  <!-- 
  <div class="d-flex align-items-center border alert-one-line p-2" role="alert">
    <svg
      class="mx-2"
      width="20"
      height="18"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0967 18.5591C22.315 17.3943 21.1271 15.9956 19.7518 14.6204C18.9964 13.8649 18.2033 13.1388 17.4585 12.5207C17.3723 12.4491 17.2568 12.4238 17.1486 12.4527C17.0404 12.4816 16.9528 12.5611 16.9138 12.6661C16.7973 12.9789 16.6075 13.2681 16.3497 13.5258C16.138 13.7376 15.9182 13.9043 15.6963 14.0212C15.604 14.0699 15.5385 14.1577 15.5182 14.2602C15.4981 14.3628 15.5253 14.4688 15.5924 14.5489C16.1922 15.2656 16.8929 16.0281 17.6186 16.754C20.1193 19.2546 22.5489 21.001 23.5273 21.0011C23.6775 21.0011 23.7951 20.9597 23.8765 20.8783C24.2607 20.494 23.6646 19.405 23.0967 18.5591Z"
        fill="#F77E17"
      />
      <path
        d="M12.9692 11.426C13.0308 11.8161 13.1007 12.2582 13.3272 12.7461C13.6353 13.4096 14.1561 13.8057 14.7203 13.8058H14.7204C15.1468 13.8058 15.576 13.5926 15.9964 13.1723C16.5922 12.5765 16.7716 11.7955 16.4764 11.083C16.0847 10.1373 14.967 9.52631 13.6289 9.52631C13.3344 9.52631 13.0267 9.55466 12.7143 9.61061C12.5921 9.63253 12.4909 9.71837 12.4495 9.83555C12.408 9.95267 12.4327 10.0831 12.5139 10.1771C12.8295 10.542 12.8942 10.9515 12.9692 11.426Z"
        fill="#F77E17"
      />
      <path
        d="M21.9246 0H0.345733C0.154782 0 0 0.154782 0 0.345733V14.9977C0 15.1887 0.154782 15.3435 0.345733 15.3435H15.1436L14.4368 14.4355C13.0061 14.4355 12.5798 13.0563 12.4549 13.0563H2.28716V2.28716H19.9832V13.0563H19.1715L21.3742 15.3435H21.9246C22.1156 15.3435 22.2704 15.1887 22.2704 14.9977V0.345733C22.2704 0.154782 22.1156 0 21.9246 0Z"
        fill="#F77E17"
      />
      <path
        d="M11.9499 9.10765C12.0148 9.16818 12.0994 9.20057 12.1858 9.20057C12.2178 9.20057 12.2501 9.19616 12.2816 9.18701C12.4996 9.12408 12.8207 9.03286 13.1134 8.95526C13.1845 8.93643 13.2476 8.89542 13.2939 8.8383C13.3747 8.73841 13.4692 8.63341 13.5385 8.55671C13.8831 8.17412 14.3552 7.6502 14.1474 7.02708C14.0403 6.71501 13.8271 6.48449 13.5307 6.36045C13.3739 6.29482 13.2038 6.26296 13.0103 6.26296C12.341 6.26296 11.4977 6.66815 10.6049 7.09713C10.2067 7.28845 9.57202 7.59334 9.20172 7.70083C9.28927 7.47956 9.46208 7.1709 9.58367 6.95352C9.79478 6.57656 10.0131 6.18673 10.1252 5.80643C10.3696 4.978 9.98898 4.53339 9.73819 4.34041C9.73782 4.34015 9.73367 4.33701 9.73329 4.33669C9.5083 4.16792 9.25772 4.08234 8.98858 4.08234C8.21861 4.08234 7.44263 4.76487 6.81908 5.31331C6.56367 5.53798 6.36506 5.70813 6.22586 5.80829C6.11474 5.88823 6.06124 6.02621 6.08948 6.16014L6.22916 6.82368C6.25149 6.92979 6.32229 7.01926 6.42032 7.06532C6.51856 7.11149 6.6326 7.10889 6.7285 7.05841C7.02748 6.90107 7.33789 6.62794 7.69762 6.31152C7.99223 6.05238 8.29696 5.78441 8.57636 5.60175C8.69418 5.52474 8.78242 5.47968 8.84593 5.45335C8.76636 5.69164 8.54977 6.07833 8.4236 6.30375C8.20925 6.68682 7.98755 7.08298 7.88325 7.4626C7.63757 8.35666 8.15696 8.77633 8.38972 8.91404C8.56158 9.01739 8.75482 9.0676 8.98039 9.0676C9.57415 9.0676 10.3185 8.70995 11.1805 8.29581C11.61 8.08949 12.0541 7.87615 12.4283 7.73844C12.4572 7.72775 12.4856 7.71764 12.5134 7.70807C12.2496 8.00135 11.9383 8.36134 11.8479 8.78234C11.8222 8.90117 11.861 9.02473 11.9499 9.10765Z"
        fill="#F77E17"
      />
    </svg>

    <div class="flex-grow-1 text-truncate" style="font-size: 11px">
      <strong>No OPD ACADEMY,</strong> você aprende a editar esses materiais de
      forma simples, a criar posts incríveis com o ChatGPT e muito mais!
    </div>
    <a
      [routerLink]="'/page/personal/opd-academy'"
      class="btn btn-success btn-sm text-nowra mr-n6"
      style="font-size: 11px"
    >
      Clique aqui e acesse agora o OPD ACADEMY →
    </a>
  </div> -->
  <span>
    <div
      *ngIf="selectedCategory == 2"
      class="d-flex align-items-center justify-center border alert-one-line p-2"
      role="alert"
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8486 31.875C15.6736 31.875 13.7236 31.2937 12.0549 30.1687C10.4049 29.0437 9.16737 27.4313 8.36112 25.3688C7.89237 24.1688 7.61112 22.8563 7.47987 21.3563C7.32987 19.575 7.44237 17.7562 7.83612 15.975C8.45487 13.1062 9.71112 10.6313 11.5486 8.56875C13.3674 6.54375 15.5236 5.175 17.9799 4.5375C19.0299 4.25625 20.0799 4.125 21.0736 4.125C22.2736 4.125 23.4549 4.33125 24.5799 4.74375C26.2486 5.3625 27.3924 6.43125 27.9361 7.93125C28.1986 8.625 28.2736 9.35625 28.1986 10.1812C28.0861 11.3437 27.7111 12.375 27.0736 13.2562C26.3424 14.2687 25.4611 14.8875 24.3924 15.15C24.2049 15.2063 23.9986 15.225 23.7736 15.225C23.6799 15.225 23.6049 15.225 23.5111 15.2062C23.1924 15.1687 22.9111 15.0375 22.7236 14.7938C22.5361 14.55 22.4611 14.2312 22.4986 13.9125C22.5549 13.5375 22.7049 13.2187 22.8549 12.9562L22.9111 12.8438C23.2111 12.2437 23.4924 11.6812 23.6424 11.0812C23.8861 10.0687 23.8861 9.3 23.6236 8.5875C23.3424 7.8375 22.8174 7.36875 22.0299 7.18125C21.7299 7.10625 21.4299 7.06875 21.1299 7.06875C20.4549 7.06875 19.7424 7.2375 18.9924 7.575C17.5111 8.25 16.2549 9.375 15.1861 11.0063C14.4549 12.1313 13.8924 13.3875 13.4799 14.85C13.1799 15.9 12.9924 17.0063 12.8799 18.15C12.8236 18.6938 12.7861 19.3313 12.7861 19.95C12.8049 21.7688 13.0674 23.2125 13.6299 24.4875C14.2486 25.9125 15.0924 26.9062 16.2361 27.5437C17.0049 27.975 17.8674 28.2 18.7861 28.2C18.9361 28.2 19.1049 28.2 19.2736 28.1813C21.2236 28.0313 22.9486 27.15 24.5236 25.5C25.3299 24.6562 26.0049 23.6812 26.5861 22.5187C26.6799 22.35 26.7736 22.1625 26.9236 22.0125C27.1111 21.8063 27.3549 21.6937 27.6174 21.6937C27.9361 21.6937 28.2174 21.8625 28.4049 22.1625C28.6299 22.5375 28.6111 22.95 28.5736 23.2125C28.4611 23.9813 28.1799 24.7313 27.7111 25.6125C26.3611 28.0313 24.5049 29.8125 22.1986 30.9187C21.0174 31.4812 19.7424 31.8 18.4299 31.8562C18.2236 31.875 18.0361 31.875 17.8486 31.875Z"
          stroke="url(#paint0_linear_722_579)"
          stroke-width="2.25"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_722_579"
            x1="18.0063"
            y1="4.125"
            x2="18.0063"
            y2="31.875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0BE8FA" />
            <stop offset="0.525" stop-color="#2A71FF" />
            <stop offset="1" stop-color="#6A2EFF" />
          </linearGradient>
        </defs>
      </svg>

      <div class="flex-grow-1 text-truncate" style="font-size: 16px">
        <strong>Atenção!</strong> Para editar os arquivos do Canva, você precisa
        <strong>duplicar o arquivo.</strong>
      </div>
      <a
        href="https://files.wecodde.com/file/wecodde/VIDEO-2025-01-14-16-01-38.mp4"
        target="_blank"
        class="btn btn-warning btn-sm text-nowra mr-n6"
        style="font-size: 16px"
      >
        Ver Tutorial
      </a>
    </div>
  </span>
  <ul class="nav nav-tabs">
    <li class="nav-item" *ngFor="let category of categories">
      <button
        type="button"
        class="nav-link"
        style="background-color: unset"
        [class.active]="selectedCategory === category.id"
        (click)="selectCategory(category.id)"
      >
        <i [class]="category.icon" style="margin-right: 6px"></i>
        {{ category.name }}
      </button>
    </li>
  </ul>

  <div class="row">
    <div class="col-6 col-md-3 mb-4" *ngFor="let material of filteredMaterials">
      <div class="card shadow-sm h-100">
        <a (click)="goToMaterialDetail(material.id)">
          <img
            [src]="material.cover"
            [alt]="material.name"
            class="card-img-top"
          />
        </a>
        <h5 class="ml-2 mt-2">{{ material.name }}</h5>
        <p class="ml-2">{{ material.description }}</p>
      </div>
    </div>
  </div>
</div>
