import { RouteInfo } from "./vertical-menu.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/page",
    title: "Início",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/page/personal/acompanhamento-execucoes",
    title: "Acompanhamento",
    icon: "",
    src: "../../../assets/img/routes/acompanhamento.svg",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "",
    title: "Alunos",
    icon: "ft-users",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/page/",
        title: "Gestão de alunos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/grupos",
        title: "Grupos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/seller",
    title: "Área do Personal",
    icon: "ft-align-left",
    class: "has-sub",
    isExternalLink: false,
    submenu: [
      {
        path: "/page/personal/desafios/2",
        title: "Desafios",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/programas/1",
        title: "Programas",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/mural",
        title: "Mural",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/seller",
    title: "Modelos",
    icon: "ft-book",
    class: "has-sub",
    isExternalLink: false,
    badge: "",
    badgeClass: "",
    submenu: [
      {
        path: "/page/personal/avaliacoes",
        title: "Avaliações",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/treinos",
        title: "Treinos",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/seller",
    title: "Banco de dados",
    icon: "ft-database",
    class: "has-sub",
    isExternalLink: false,
    submenu: [
      {
        path: "/page/personal/exercicios",
        title: "Exercícios",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/categorias",
        title: "Categorias",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },

  {
    path: "/seller",
    title: "Minha Loja",
    icon: "",
    src: "../../../assets/img/routes/store.svg",
    class: "has-sub",
    badge: "Novo",
    badgeClass: "badge badge-pill badge-danger ml-n4",
    isExternalLink: false,
    submenu: [
      {
        path: "/page/personal/finance-store",
        title: "Ver Loja",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "Novo",
        badgeClass: "badge badge-pill badge-danger",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/finance",
        title: "Financeiro",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "badge-pill badge-danger",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },

  // {
  //   path: "/seller",
  //   title: "Financeiro",
  //   icon: "ft-dollar-sign",
  //   class: "has-sub",
  //   isExternalLink: false,
  //   badge: "",
  //   badgeClass: "badge badge-pill badge-danger",
  //   submenu: [
  //     {
  //       path: "/page/personal/finance/account",
  //       title: "Conta",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/page/personal/finance-dashboard",
  //       title: "Produtos / Serviços",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/page/personal/finance/reports",
  //       title: "Relatórios e Extratos",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/page/personal/finance/subscriptions",
  //       title: "Assinaturas",
  //       icon: "ft-arrow-right submenu-icon",
  //       class: "",
  //       badge: "",
  //       badgeClass: "",
  //       isExternalLink: false,
  //       submenu: [],
  //     },
  //   ],
  // },

  {
    path: "/seller",
    title: "Meu Usuário",
    icon: "ft-user",
    class: "has-sub",
    isExternalLink: false,
    submenu: [
      {
        path: "/page/personal/info",
        title: "Minhas Informações",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
      {
        path: "/page/personal/plano",
        title: "Plano",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
  {
    path: "/page/personal/indicacao",
    title: "Indique e Ganhe",
    icon: "fa fa-money",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },

  {
    path: "/page/personal/opd-academy",
    title: "OPD Academy",
    icon: "fa fa-graduation-cap",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/page/personal/addcional-material",
    title: "Materiais de Divulgação",
    icon: "",
    src: "../../../assets/img/routes/materiais.svg",
    class: "",
    badge: "Novo",
    badgeClass: "badge badge-pill badge-danger",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/",
    title: "Ajuda",
    icon: "ft-help-circle",
    class: "has-sub",
    isExternalLink: false,
    submenu: [
      {
        path: "",
        title: "Treinamento Inicial",
        isTreinamentoInicial: true,
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      },
    ],
  },
];
/*
{
  path: '', title: 'Treinos', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  submenu: [
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
]
},*/
