import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Location } from "@angular/common";
@Component({
  selector: "app-material-detail",
  templateUrl: "./material-detail.component.html",
  styleUrls: ["./material-detail.component.scss"],
})
export class MaterialDetailComponent implements OnInit {
  marketingMaterialId: number | null = null;
  materials: any[] = [];
  materialCategory: any[] = [];
  isMobileAppAccess = null;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private location: Location
  ) {
    {
      this.route.queryParams.subscribe((params) => {
        if (params["isMobile"]) {
          this.isMobileAppAccess = true;
        }
      });
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.marketingMaterialId = Number(params.get("marketingMaterialId"));
      console.log(
        "marketingMaterialId (Observable):",
        this.marketingMaterialId
      );
    });

    const navigationState = history.state;
    console.log("Estado de navegação:", navigationState);

    if (navigationState && Object.keys(navigationState).length > 0) {
      if (navigationState.materials) {
        this.materials = navigationState.materials;
      }

      if (navigationState.selectedAllMaterial) {
        this.materialCategory = navigationState.selectedAllMaterial;
      }
    }

    if (!this.materials || !this.materialCategory) {
      console.error("Nenhum material encontrado na navegação");
    }
  }

  downloadMaterial(url: string, name: string): void {
    if (this.isMobileAppAccess) {
      console.log("Mobile", this.isMobileAppAccess);
      window.open(url, "_blank");
    } else {
      this.http.get(url, { responseType: "blob" }).subscribe(
        (blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = name || "material.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(blobUrl);
        },
        (error) => {
          console.error("Erro ao baixar o arquivo:", error);
        }
      );
    }
  }
  goBack(): void {
    this.location.back();
  }
}
