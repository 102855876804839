import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import Api from "./helpers/api";
import { MaintenanceService } from "./shared/services/maintenance.service";

declare let fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  apiCall = this.api.new().silent();

  constructor(
    private api: Api,
    private maintenanceService: MaintenanceService,
    private router: Router
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        fbq("track", "PageView");
      }
    });
  }

  ngOnInit(): void {
    this.maintenanceService.getMaintenanceStatus().subscribe((data) => {
      if (data.maintenance == true) {
        this.router.navigate(["pages/maintenance"]);
      }
    });

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
    // this.route.queryParams.subscribe(async (params) => {
    //   if (params && params['token']) {

    //   }else{
    //     let user = await this.me.get().pipe(take(1)).toPromise()

    //     if (user) {
    //       self.apiCall.put('personal/insertlog').subscribe();
    //     }
    //   }

    // })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
