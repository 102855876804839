import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-additional-material",
  templateUrl: "./additional-material.component.html",
  styleUrls: ["./additional-material.component.scss"],
})
export class AdditionalMaterialComponent implements OnInit {
  categories = [
    {
      id: 1,
      name: "Instagram",
      cover: null,
      description: "Posts de Instagram",
      icon: "fa fa-instagram",
      isActive: true,
      createdAt: "2025-01-07T12:54:29.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 2,
      name: "Editáveis",
      cover: null,
      description: "Artes editáveis",
      icon: "fa fa-edit",
      isActive: true,
      createdAt: "2025-01-07T12:54:30.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 3,
      name: "Extras",
      cover: null,
      description: "Materiais de artes",
      icon: "fa fa-plus",
      isActive: true,
      createdAt: "2025-01-07T12:54:30.000Z",
      updatedAt: null,
      deletedAt: null,
    },
  ];

  materials = [
    {
      id: 9,
      name: "Idéias de Copy",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_ideias_copy.png",
      description: null,
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 3,
      createdAt: "2025-01-09T17:20:45.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 1,
      name: "Imagens de Fundo",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_instagram_background.png",
      description: "Imagens para usar de background.",
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 1,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 2,
      name: "Post: Funcionalidades App do Aluno",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_instagram_posts.png",
      description: "Post sobre funcionalidades do app do aluno.",
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 1,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 3,
      name: "Stories Diversos",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_instagram_stories.png",
      description: "Stories sobre assuntos variados",
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 1,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 4,
      name: "Tutorial: Criando e editando uma arte",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_editaveis_aula.png",
      description: null,
      url: "https://wecodde.s3.us-west-000.backblazeb2.com/3+-+Criando+e+Editando+uma+arte.mp4",
      isActive: true,
      marketingMaterialCategoryId: 2,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 5,
      name: "Posts Editáveis",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_editaveis_canva.png",
      description: null,
      url: "https://opersonaldigital.com.br/posts-editaveis-v1",
      isActive: true,
      marketingMaterialCategoryId: 2,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 6,
      name: "Logos OPD",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 3,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 7,
      name: "Mockups Aluno",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_mockups_aluno.png",
      description: null,
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 3,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 8,
      name: "Vídeo Apresentação: App do Aluno",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_aula_app_aluno.png",
      description: null,
      url: "https://wecodde.s3.us-west-000.backblazeb2.com/video_apresentacao_aluno.mp4",
      isActive: true,
      marketingMaterialCategoryId: 3,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 10,
      name: "Post: Uso do App do Aluno",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_instagram_posts.png",
      description:
        "Post sobre como utilizar as funcionalidades do app do aluno.",
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 1,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 11,
      name: "Posts: Captação de Alunos",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_instagram_posts.png",
      description:
        "Imagens para posts de captação de alunos, com opções com e sem fundo para personalização.",
      url: null,
      isActive: true,
      marketingMaterialCategoryId: 1,
      createdAt: "2025-01-07T12:55:36.000Z",
      updatedAt: null,
      deletedAt: null,
    },
  ];

  allMaterials = [
    {
      id: 77,
      name: "Uso do App Aluno - 7",
      cover: "https://files.wecodde.com/file/wecodde/story+5.7.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+7.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:28.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 76,
      name: "Uso do App Aluno - 6",
      cover: "https://files.wecodde.com/file/wecodde/story+5.6.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+6.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:28.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 75,
      name: "Uso do App Aluno - 5",
      cover: "https://files.wecodde.com/file/wecodde/story+5.5.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+5.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:28.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 74,
      name: "Uso do App Aluno - 4",
      cover: "https://files.wecodde.com/file/wecodde/story+5.4.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+4.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 73,
      name: "Uso do App Aluno - 3",
      cover: "https://files.wecodde.com/file/wecodde/story+5.3.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+3.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 72,
      name: "Uso do App Aluno - 2",
      cover: "https://files.wecodde.com/file/wecodde/story+5.2.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+2.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 78,
      name: "Apresentação App Aluno - 7",
      cover: "https://files.wecodde.com/file/wecodde/story+4.7.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+7.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:26.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 68,
      name: "Apresentação App Aluno - 4",
      cover: "https://files.wecodde.com/file/wecodde/story+4.4.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+4.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:26.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 69,
      name: "Apresentação App Aluno - 5",
      cover: "https://files.wecodde.com/file/wecodde/story+4.5.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+5.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:26.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 70,
      name: "Apresentação App Aluno - 6",
      cover: "https://files.wecodde.com/file/wecodde/story+4.6.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+6.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:26.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 71,
      name: "Uso do App Aluno - 1",
      cover: "https://files.wecodde.com/file/wecodde/story+5.1.png",
      description:
        "Conjunto de stories com dicas práticas sobre como utilizar as principais ferramentas do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Uso+do+App+Aluno+-+1.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:26.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 67,
      name: "Apresentação App Aluno - 3",
      cover: "https://files.wecodde.com/file/wecodde/story+4.3.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+3.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:25.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 66,
      name: "Apresentação App Aluno - 2",
      cover: "https://files.wecodde.com/file/wecodde/story+4.2.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+2.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:25.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 65,
      name: "Apresentação App Aluno - 1",
      cover: "https://files.wecodde.com/file/wecodde/story+4.1.png",
      description:
        "Destaca as funcionalidades e diferenciais do aplicativo do aluno.",
      url: "https://files.wecodde.com/file/wecodde/Apresentação+App+Aluno+-+1.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:25.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 64,
      name: "Captação de alunos - 3",
      cover: "https://files.wecodde.com/file/wecodde/story+3.png",
      description:
        "Enfatiza como o OPD pode transformar a rotina de treinos, incentivando o agendamento de consultorias.",
      url: "https://files.wecodde.com/file/wecodde/Captação+de+alunos+-+3.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:25.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 63,
      name: "Captação de alunos - 2",
      cover: "https://files.wecodde.com/file/wecodde/story+2.png",
      description:
        "Destaca os benefícios da consultoria online, com foco no acompanhamento inteligente e personalizado via app.",
      url: "https://files.wecodde.com/file/wecodde/Captação+de+alunos+-+2.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:24.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 62,
      name: "Captação de alunos - 1",
      cover: "https://files.wecodde.com/file/wecodde/story+1.png",
      description:
        "Destaca os benefícios da consultoria e convida os interessados a entrar em contato.",
      url: "https://files.wecodde.com/file/wecodde/Captação+de+alunos+-+1.png",
      isActive: true,
      marketingMaterialId: 3,
      createdAt: "2025-01-09T19:05:24.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 60,
      name: "Sem fundo - Post 2",
      cover: "https://files.wecodde.com/file/wecodde/sem+fundo+2.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Sem+fundo+-+Post+2.png",
      isActive: true,
      marketingMaterialId: 11,
      createdAt: "2025-01-09T18:59:23.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 56,
      name: "Com fundo - Post 1",
      cover: "https://files.wecodde.com/file/wecodde/com+fundo+1.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Com+fundo+-+Post+1.png",
      isActive: true,
      marketingMaterialId: 11,
      createdAt: "2025-01-09T18:59:23.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 57,
      name: "Com fundo - Post 2",
      cover: "https://files.wecodde.com/file/wecodde/com+fundo+2.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Com+fundo+-+Post+2.png",
      isActive: true,
      marketingMaterialId: 11,
      createdAt: "2025-01-09T18:59:23.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 58,
      name: "Com fundo - Post 3",
      cover: "https://files.wecodde.com/file/wecodde/com+fundo+3.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Com+fundo+-+Post+3.png",
      isActive: true,
      marketingMaterialId: 11,
      createdAt: "2025-01-09T18:59:23.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 59,
      name: "Sem fundo - Post 1",
      cover: "https://files.wecodde.com/file/wecodde/sem+fundo+1.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Sem+fundo+-+Post+1.png",
      isActive: true,
      marketingMaterialId: 11,
      createdAt: "2025-01-09T18:59:23.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 61,
      name: "Sem fundo - Post 3",
      cover: "https://files.wecodde.com/file/wecodde/sem+fundo+3-11.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Sem+fundo+-+Post+3.png",
      isActive: true,
      marketingMaterialId: 11,
      createdAt: "2025-01-09T18:59:23.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 55,
      name: "Uso do App - 7",
      cover: "https://files.wecodde.com/file/wecodde/18rkr88m5pthr9j.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+07.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:55:00.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 54,
      name: "Uso do App - 6",
      cover: "https://files.wecodde.com/file/wecodde/18rkr88m5pthhdv.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+06.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:55:00.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 53,
      name: "Uso do App - 5",
      cover: "https://files.wecodde.com/file/wecodde/sgi2cib8m5pthapk.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+05.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:55:00.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 50,
      name: "Uso do App - 2",
      cover: "https://files.wecodde.com/file/wecodde/joxx8m5ptglxy.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+02.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:54:59.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 49,
      name: "Uso do App - 1",
      cover: "https://files.wecodde.com/file/wecodde/joxx8m5ptgcgz.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+01.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:54:59.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 51,
      name: "Uso do App - 3",
      cover: "https://files.wecodde.com/file/wecodde/bdky6m8m5ptgtae.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+03.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:54:59.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 52,
      name: "Uso do App - 4",
      cover: "https://files.wecodde.com/file/wecodde/sgi2cib8m5pth0oc.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v2+F+-+04.png",
      isActive: true,
      marketingMaterialId: 10,
      createdAt: "2025-01-09T18:54:59.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 48,
      name: "Funcionalidades do App - 7",
      cover: "https://files.wecodde.com/file/wecodde/78bbh8m5pt7mow.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+07.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 47,
      name: "Funcionalidades do App - 6",
      cover: "https://files.wecodde.com/file/wecodde/78bbh8m5pt7ext.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+06.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 46,
      name: "Funcionalidades do App - 5",
      cover: "https://files.wecodde.com/file/wecodde/sgi2cib8m5pt7agw.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+05.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 45,
      name: "Funcionalidades do App - 4",
      cover: "https://files.wecodde.com/file/wecodde/joxx8m5pt75vs.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+04.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 44,
      name: "Funcionalidades do App - 3",
      cover: "https://files.wecodde.com/file/wecodde/43e7d8m5pt70tm.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+03.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 43,
      name: "Funcionalidades do App - 2",
      cover: "https://files.wecodde.com/file/wecodde/2e8sp7r8m5pt6p9g.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+02.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:27.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 42,
      name: "Funcionalidades do App - 1",
      cover: "https://files.wecodde.com/file/wecodde/iiaf8m5pt63wn.jpeg",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Apresentação+APP+v1+F+-+01.png",
      isActive: true,
      marketingMaterialId: 2,
      createdAt: "2025-01-09T18:53:26.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 41,
      name: "Tutorial: Criando e editando uma arte",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_editaveis_aula.png",
      description: null,
      url: "https://wecodde.s3.us-west-000.backblazeb2.com/3+-+Criando+e+Editando+uma+arte.mp4",
      isActive: true,
      marketingMaterialId: 4,
      createdAt: "2025-01-09T17:56:12.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 40,
      name: "Vídeo Apresentação: App do Aluno",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_aula_app_aluno.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/video_apresentacao_aluno.mp4",
      isActive: true,
      marketingMaterialId: 8,
      createdAt: "2025-01-09T17:46:56.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 39,
      name: "Posts Editáveis",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_editaveis_canva.png",
      description: null,
      url: "https://opersonaldigital.com.br/posts-editaveis-v1",
      isActive: true,
      marketingMaterialId: 5,
      createdAt: "2025-01-09T17:26:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 38,
      name: "Idéias de Copy",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_ideias_copy.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Ideias+de+Copys+para+Redes+Sociais.pdf",
      isActive: true,
      marketingMaterialId: 9,
      createdAt: "2025-01-09T17:21:31.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 37,
      name: "Logo - 14",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+9.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:21.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 36,
      name: "Logo - 13",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+9-1.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:21.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 35,
      name: "Logo - 12",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+8.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:21.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 34,
      name: "Logo - 11",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+8-1.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:20.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 33,
      name: "Logo - 10",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+7.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:20.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 32,
      name: "Logo - 9",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+3.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:20.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 31,
      name: "Logo - 8",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+2.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:19.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 30,
      name: "Logo - 7",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Group+1.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:19.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 29,
      name: "Logo - 6",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/6.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:19.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 28,
      name: "Logo - 5",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/5.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:19.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 27,
      name: "Logo - 4",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/4.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:18.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 24,
      name: "Logo - 1",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/1.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:18.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 25,
      name: "Logo - 2",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/2.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:18.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 26,
      name: "Logo - 3",
      cover:
        "https://files.wecodde.com/file/wecodde/opd_materiais_extras_extras_logos.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/3.png",
      isActive: true,
      marketingMaterialId: 6,
      createdAt: "2025-01-09T17:15:18.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 21,
      name: "Tela inicial APP",
      cover: "https://files.wecodde.com/file/wecodde/home.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Tela+inicial+APP.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 4,
      name: "Imagem de Fundo (Story) - Branca",
      cover: "https://files.wecodde.com/file/wecodde/branco+story.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Imagem de Fundo (Story) - Branca.png",
      isActive: true,
      marketingMaterialId: 1,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 5,
      name: "Imagem de Fundo (Story) - Laranja",
      cover: "https://files.wecodde.com/file/wecodde/laranja+story.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Imagem de Fundo (Story) - Laranja.png",
      isActive: true,
      marketingMaterialId: 1,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 6,
      name: "Imagem de Fundo (Story) - Preto",
      cover: "https://files.wecodde.com/file/wecodde/preto+story.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Imagem de Fundo (Story) - Preto.png",
      isActive: true,
      marketingMaterialId: 1,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 7,
      name: "Cronômetro Descanso 01",
      cover:
        "https://files.wecodde.com/file/wecodde/Cronômetro+Descanso+01.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Cronômetro+Descanso+01.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 8,
      name: "Cronômetro Descanso 02",
      cover:
        "https://files.wecodde.com/file/wecodde/Cronômetro+Descanso+02.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Cronômetro+Descanso+02.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 9,
      name: "Evolução de Carga 01",
      cover: "https://files.wecodde.com/file/wecodde/EC+anual.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Evolução+de+Carga+01.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 10,
      name: "Evolução de Carga 02",
      cover: "https://files.wecodde.com/file/wecodde/EC.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Evolução+de+Carga+02.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 11,
      name: "Evolução de Carga 03",
      cover: "https://files.wecodde.com/file/wecodde/EC+2.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Evolução+de+Carga+03.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 12,
      name: "Feedback Carga",
      cover: "https://files.wecodde.com/file/wecodde/feedback.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Feedback+Carga.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 13,
      name: "Feedback Treino",
      cover: "https://files.wecodde.com/file/wecodde/darr+feeedback.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Feedback+Treino.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 14,
      name: "Finalizar Treino",
      cover: "https://files.wecodde.com/file/wecodde/Fim.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Finalizar+Treino.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 15,
      name: "Frequência de Treinos Ano",
      cover: "https://files.wecodde.com/file/wecodde/frreq+ano.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Frequência+de+Treinos+Ano.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 16,
      name: "Frequência de Treinos - Mês",
      cover: "https://files.wecodde.com/file/wecodde/freq+ms.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Frequência+de+Treinos+-+Mês.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 17,
      name: "Frequência de Treinos - Semana",
      cover: "https://files.wecodde.com/file/wecodde/frrq+smana.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Frequência+de+Treinos+-+Semana.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 18,
      name: "Observação sobre o Exercício",
      cover: "https://files.wecodde.com/file/wecodde/obs.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Observação+sobre+o+Exercício.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 19,
      name: "Tela de Treino 01",
      cover: "https://files.wecodde.com/file/wecodde/teino+1.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Tela+de+Treino+01.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 20,
      name: "Tela de Treino 02",
      cover: "https://files.wecodde.com/file/wecodde/teino+2.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Tela+de+Treino+02.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 22,
      name: "Treino Concluído 01",
      cover: "https://files.wecodde.com/file/wecodde/fim+teino.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Treino+Concluído+01.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 23,
      name: "Treino Concluído 02",
      cover: "https://files.wecodde.com/file/wecodde/fim+teino.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Treino+Concluído+02.png",
      isActive: true,
      marketingMaterialId: 7,
      createdAt: "2025-01-07T12:57:38.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 1,
      name: "Imagem de Fundo (Feed) - Branca",
      cover: "https://files.wecodde.com/file/wecodde/branco+feed.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Imagem de Fundo (Feed) - Branca.png",
      isActive: true,
      marketingMaterialId: 1,
      createdAt: "2025-01-07T12:57:37.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 3,
      name: "Imagem de Fundo (Feed) - Preto",
      cover: "https://files.wecodde.com/file/wecodde/preto+feed.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Imagem de Fundo (Feed) - Preto.png",
      isActive: true,
      marketingMaterialId: 1,
      createdAt: "2025-01-07T12:57:37.000Z",
      updatedAt: null,
      deletedAt: null,
    },
    {
      id: 2,
      name: "Imagem de Fundo (Feed) - Laranja",
      cover: "https://files.wecodde.com/file/wecodde/laranja+feed.png",
      description: null,
      url: "https://files.wecodde.com/file/wecodde/Imagem de Fundo (Feed) - Laranja.png",
      isActive: true,
      marketingMaterialId: 1,
      createdAt: "2025-01-07T12:57:37.000Z",
      updatedAt: null,
      deletedAt: null,
    },
  ];

  isMobileAppAccess = null;
  selectedCategory = 1;
  filteredMaterials = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    {
      this.route.queryParams.subscribe((params) => {
        if (params["isMobile"]) {
          this.isMobileAppAccess = true;
        }
      });
    }
  }

  ngOnInit(): void {
    this.filterMaterials();
  }

  selectCategory(categoryId: number): void {
    this.selectedCategory = categoryId;
    this.filterMaterials();
  }

  filterMaterials(): void {
    this.filteredMaterials = this.materials.filter(
      (material) =>
        material.marketingMaterialCategoryId === this.selectedCategory
    );
  }

  downloadMaterial(url: string, name: string): void {
    if (this.isMobileAppAccess) {
      console.log("Mobile", this.isMobileAppAccess);
      window.open(url, "_blank");
    } else {
      this.http.get(url, { responseType: "blob" }).subscribe(
        (blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = name || "material.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(blobUrl);
        },
        (error) => {
          console.error("Erro ao baixar o arquivo:", error);
        }
      );
    }
  }

  goToMaterialDetail(materialId: number): void {
    this.route.queryParams.subscribe((queryParams) => {
      const isMobile = queryParams["isMobile"];

      const selectedMaterial = this.materials.find(
        (material) => material.id === materialId
      );

      if (!selectedMaterial) {
        console.error("Material não encontrado");
        return;
      }

      if (selectedMaterial.url) {
        window.open(selectedMaterial.url, "_blank");
      } else {
        const filteredMaterials = this.allMaterials
          .filter((material) => material.marketingMaterialId === materialId)
          .sort((a, b) => a.id - b.id);

        this.router.navigate(["/page/personal/material", materialId], {
          state: {
            materials: filteredMaterials,
            selectedAllMaterial: selectedMaterial,
          },
          queryParams: isMobile ? { isMobile: true } : {},
        });
      }
    });
  }
}
